export function getOrder(state) {
  return state.order;
}

export function getOrderCount(state) {
  return state.orderCount;
}

export function getClient(state) {
  return state.client;
}
