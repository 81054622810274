<template>
  <div class="MainLayout">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'MainLayout',
};
</script>
