const messages = {
  en: {
    save: "Save",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    totalPrice: "Total price",
    discount: "Discount",
    elements: "Elements",
    client: "Client",
    user: "User",
    cashier: "Cashier",
    seller: "Seller",
    totalDiscont: "Total discount",
    print: "Print",
    clear: "Clear",
    pay: "Pay",
    postponement: "Postponement",
    name: "Name",
    namePerson: "Name",
    price: "Price",
    qty: "qty",
    total: "Total",
    basket: "Basket",
    application: "Application",
    code: "Code",
    home: "Home",
    payment: "Payment",
    cash: "Cash",
    card: "Card",
    combination: "Combination",
    currency: "Currency",
    changeClient: "Select client",
    deleteProduct: "Want to delete a product?",
    delete: "Delete",
    savePostPonement: "Save postponement",
    warning: "Warning",
    printMessage: "Want to withdraw on check?",
    surrender: "Surrender",
    noProducts: "No Products",
    add: "Add",
    close: "Close",
    addClient: "Add new client",
    number: "Phone",
    birthday: "Date of Birth",
    phone: "Phone number",
    note: "Note",
    all: "All",
    logOutMessage: "Are you sure you want to log out?",
    exit: "Exit",
    warehouse: "Warehouse",
    remainder: "Remainder",
    clearCart: "Want to empty your trash?",
    paymentMessageCart: "Did you withdraw money from the card? Delivery:",
    enterPassword: "Enter Password",
    ok: "Ok",
    totalDiscount: "Total Discount",
    canceled: "Canceled"
  },
  ru: {
    save: "Сохранить",
    yes: "Да",
    no: "Нет",
    cancel: "Отменить",
    totalPrice: "Общая сумма",
    discount: "Скидка",
    elements: "Элементы",
    client: "Клиент",
    user: "User",
    cashier: "Кассир",
    seller: "Продовец",
    totalDiscont: "Общая скидка",
    print: "Печать",
    clear: "Очистить",
    pay: "Оплатить",
    postponement: "Отсрочка",
    name: "Наименование",
    namePerson: "Имя",
    price: "Цена",
    qty: "Кол",
    total: "Сумма",
    basket: "Корзина",
    application: "Заявки",
    code: "Код",
    home: "Главная",
    payment: "Оплата",
    cash: "Наличный",
    card: "Карта",
    combination: "Комбинация",
    currency: "Валюта",
    changeClient: "Выберите клиент",
    deleteProduct: "Хотите удалить продукт?",
    delete: "Удалить",
    savePostPonement: "Сохранить заявку",
    warning: "Предупреждение",
    printMessage: "Хотите вывести на чек?",
    surrender: "Сдача",
    noProducts: "Нет товаров",
    add: "Добавить",
    close: "Закрыть",
    addClient: "Добавить новый клиент",
    number: "Номер",
    birthday: "Дата рождения",
    phone: "Номер телефон",
    note: "Примечание",
    all: "Все",
    logOutMessage: "Вы уверены, что хотите выйти из системы?",
    exit: "Выйти",
    warehouse: "Склад",
    remainder: "Остаток",
    clearCart: "Хотите очистить корзину?",
    paymentMessageCart: "Вы снимали деньги с карты? Сдача:",
    enterPassword: "Введите пароль",
    ok: "Ок",
    totalDiscount: "Общая скидка",
    canceled: "Отменено"
  }
};

export default messages;
